const en = {
  link: "Link Wallet",
  current: "Current：",
  target: "Target：",
  countDown: "Countdown to distance explosion",
  day: "Days",
  hour: "Hours",
  minute: "Minutes",
  second: "Seconds",
  lowest: "Minimum Investment",
  highest: "Maximum Investment",
  ticket: "admission ticket  ≈",
  invest: "Invest",
  property: "My Assets",
  usdt: "USDT Amount",
  ebw: "EBW Amount",
  eh: "EH Amount",
  ew: "EW Amount",
  earning: "My Income",
  periods: "Periods",
  periodsStatus: "Current status",
  income: "The principal and EBW that should be returned if the position is not completed.",
  receive: "Receive",
  pledge: "Pledge Income",
  limit: "Stock Limit",
  recovery: "50% principal",
  extra: "50% principal+12% additional income",
  invite: "My Invitation",
  invitedAddress: "My Inviter's Address",
  bind: "Bind",
  invitingAddress: "My Invitation Address",
  copy: "Copy",
  static: "Total Static Income",
  refIncome: "Benefits from referrals",
  dynamic: "Total Dynamic Income",
  team: "Team Level",
  teamReward: "Overall team performance",
  TeamDividend: "Dividends obtained through team performance",
  address: "The Address Of The Player I Invited",
  NumberOfPeopleInvited: "Number of people I invited",
};

export default en;
