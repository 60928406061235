const zh = {
  link: "连接钱包",
  current: "当前：",
  target: "目标：",
  countDown: "距离爆仓倒计时",
  day: "天",
  hour: "时",
  minute: "分",
  second: "秒",
  lowest: "最低投入金额",
  highest: "封顶投入金额",
  ticket: "门票 ≈",
  invest: "投入",
  property: "我的资产",
  usdt: "USDT数量",
  ebw: "EBW数量",
  eh: "EH数量",
  ew: "EW数量",
  earning: "我的收益",
  periods: "期数",
  periodsStatus: "当期状态",
  income: "爆仓未完成应该返还的本金及EBW",
  receive: "领取",
  pledge: "爆仓收益",
  limit: "爆仓额度",
  recovery: "50%本金",
  extra: "50%本金 + 额外收益12%",
  invite: "我的邀请",
  invitedAddress: "我的邀请人地址",
  bind: "绑定",
  invitingAddress: "我的邀请地址",
  copy: "复制",
  static: "总静态收益",
  refIncome: "通过直推获得的收益",
  dynamic: "总动态收益",
  team: "团队等级",
  teamReward: "团队业绩",
  TeamDividend: "通过团队业绩获得的分红",
  address: "我邀请的玩家地址",
  NumberOfPeopleInvited: "我邀请的人数",
};

export default zh;
